import { useState } from "react";
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";

import { useDebounce } from "hooks/useDebounce";
import { IKek, PageFilter } from "components/pageFilter/pageFilter.component";
import { TableAskueEquipments } from "./askueEquipmentTable.component";
import { SimpleSearch } from "components/table/subcomponent/simpleSearch.component";
import IntroSection from "components/intro-section/intro-section";
import { CustomTypography } from "components/customTypography/custom-typography";
import {
  ASKUE_EQUIPMENTS,
  ASKUE_FILTER_DEFAULT,
  ASKUE_SCHEME_HARDCODED,
  seo,
  subtitle,
  title,
  titleByKey,
} from "./const";
import { composeSearchFilter } from "./utils";
import { SGrid, SMain, SWrap } from "./styles";

import bgImg from "./images/hero_bg.png";

interface IProp {
  [key: string]: number;
}

let valLength: any = undefined;

export const AskueEquipment = () => {
  const [searchVal, setSearch] = useState("");
  const [columnFilter, setColumnFilter] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState<IProp | {}>({});

  const newFilt = composeSearchFilter<IKek>(columnFilter);
  const debouncedSearch = useDebounce(searchVal, 500);

  const setSearchValue = (e) => {
    e.models ? setSearch(e.models[0]) : setSearch(e.manufacturer);
  };

  const handleColumnFilter = ({ filtersArr }) => {
    setColumnFilter(filtersArr);
  };

  const getDataLength = (a) => setDataLength((s) => ({ ...s, ...a }));

  valLength = !isEmpty(dataLength)
    ? Object.values(dataLength)?.reduce(function (sum, cur) {
        return sum + cur;
      })
    : Object.keys(ASKUE_EQUIPMENTS).length;

  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
      </Helmet>
      <SMain>
        <IntroSection title={title} subtext={subtitle} bgImg={bgImg} />
        <SGrid>
          <div>
            <PageFilter
              dataLength={valLength ?? 0}
              onApplyFilter={handleColumnFilter}
              scheme={ASKUE_SCHEME_HARDCODED}
              defaultFilter={newFilt ?? ASKUE_FILTER_DEFAULT}
              titleByKey={titleByKey}
            />
          </div>
          <SWrap>
            <SimpleSearch
              serchValue={searchVal}
              setSearchValue={setSearch}
              onItemClick={setSearchValue}
              onApplyFilter={handleColumnFilter}
              list={ASKUE_EQUIPMENTS}
            />
            {valLength === 0 && isEmpty(newFilt) ? (
              <div className="empty">
                <CustomTypography variant="p4_regular" className="empty">
                  Фильтрация не дала результатов
                </CustomTypography>
              </div>
            ) : (
              Object.keys(ASKUE_EQUIPMENTS).map((item) => {
                return (
                  <TableAskueEquipments
                    key={`item-${item}`}
                    title={
                      ASKUE_SCHEME_HARDCODED["categorys"].find((e) => {
                        return e.key === item;
                      })?.name ?? ""
                    }
                    data={ASKUE_EQUIPMENTS[item]}
                    globalFilter={debouncedSearch}
                    filterByColumn={columnFilter}
                    onRowsChange={(b) => getDataLength({ [item]: b.length })}
                  />
                );
              })
            )}
          </SWrap>
        </SGrid>
      </SMain>
    </>
  );
};
